import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { pushRouteWithQuery } from "../../lib/routes";
import { colors, font, fontSizing, spacing } from "../theme";

export interface RichTextProps {
  serif?: boolean;
  brandon?: boolean;
}

const Root = styled.div<RichTextProps>`
  hyphens: auto;
  overflow-wrap: break-word;

  line-height: 2;

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  ${() => {
    let classes = "";
    for (let i = 1; i < 9; i++) {
      classes += `.ql-indent-${i}{text-indent: ${i * 2}em}`;
    }
    return classes;
  }}

  * {
    font-weight: ${font.weightMedium};
    font-family: ${font.secondary};
    line-height: 1.8;
    ${fontSizing("text", 28)}
  }
  sub,sup{
    ${fontSizing("text", 24)}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${fontSizing("headline", 32)}
      hyphens:auto;
      color: ${colors.blue};
      margin: 0 0 0.6em 0;
      line-height: 1.25;
      font-weight: ${font.weightMedium};
      font-family: ${font.main};
       strong {
        font-family: ${font.main};
        font-weight: ${font.weightMedium};
      }
      &:not(:first-child) {
        margin-top: 0.6em;
      }
  }
  h1,h2{
   ${fontSizing("headline", 32)}
  }
  h3,h4{
    ${fontSizing("text", 28)}
  }
  /* h3{
    ${fontSizing("headline", 48)}
  }
  h4{
    ${fontSizing("headline", 32)}
  }
  h5,h6{
    ${fontSizing("headline", 24)}
  } */

  p {
    margin: 0;
    font-family: ${font.secondary};
    ${spacing("margin-bottom", 48)}
    display:block;
    &+ul{
      ${spacing("margin-top", 24, true)}
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li{
      &:before {
        left: 0;
        color: ${colors.pigeonLight};
        position: absolute;
        content: "•";
      }
    }
  }

  ol {
    list-style: decimal;
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
      }
    }
  }

  li {
    position: relative;

    font-family: ${font.main};
    ${fontSizing("text", 24)}
    font-weight: ${font.weightRegular};
    padding-left: calc(4px * 8);
    margin: 0;
    ${spacing("margin-bottom", 16)};
    &:last-child{
      ${spacing("margin-bottom", 48)}
    }
    *{
      font-family: ${font.main};
      font-weight: ${font.weightRegular};
      ${fontSizing("text", 24)}
    }
     sub,sup{
      ${fontSizing("text", 16)}
    }

  }
  a{
    color: ${colors.blue};
    text-decoration: none;
    font-weight: ${font.weightBold};
    transition: color 120ms ease-out;
    :active {
      color: ${colors.bluegreyDark};
    }
    :hover,
    :focus {
      color: ${colors.pigeon};
    }
  }
  strong{
    font-weight:${font.weightBold};
  }
  ${p =>
    p.serif &&
    css`
      font-weight: ${font.weightRegular};
      font-family: ${font.secondary};
    `}
`;

type Props = {
  html: string;
  className?: string;
} & RichTextProps &
  HTMLAttributes<HTMLDivElement>;

const clickOnATag = e => {
  if (e.target.tagName === "A") {
    if (e.target.origin === window.location.origin) {
      e.preventDefault();
      pushRouteWithQuery(e.target.pathname, {});
    } else {
      const isMail = e.target.href.includes("mailto");
      if (isMail) e.target.target = "_self";
    }
  }
};

export default function RichText(props: Props) {
  const { html, className, ...rest } = props;
  // split into two tag groups in case there is a <br/><br/> kind of double linebreak
  // e.g. <p>lorem<br/><br/>ipsum</p> is transformed to <p>lorem</p><p>ipsum</p>
  // in order to be BITV compliant by avoiding linebreak based paragraphs or headers
  // see https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1d-inhalt-gegliedert
  const parsedHtml = html
    .replace(/(?<=(<p>([^<]*(<br\/>)?[^<]*))*)<br\/><br\/>/g, "</p><p>")
    .replace(/(?<=(<h1>([^<]*(<br\/>)?[^<]*))*)<br\/><br\/>/g, "</h1><h1>")
    .replace(/(?<=(<h2>([^<]*(<br\/>)?[^<]*))*)<br\/><br\/>/g, "</h2><h2>")
    .replace(/(?<=(<h3>([^<]*(<br\/>)?[^<]*))*)<br\/><br\/>/g, "</h3><h3>")
    .replace(/(?<=(<h4>([^<]*(<br\/>)?[^<]*))*)<br\/><br\/>/g, "</h4><h4>");

  return (
    <Root
      className={`richtext ${className || ""}`}
      dangerouslySetInnerHTML={{
        __html: parsedHtml.replace(/h1/g, "h2")
      }}
      {...rest}
      onClick={clickOnATag}
    />
  );
}
